<template>
    <form-modal
        ref="formModal"
        :title="titleFormatted"
        size="xl">
        <template v-slot:form>
            <b-table
                hover
                responsive
                :fields="fields"
                :busy="loading"
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
            >
                <template v-slot:table-busy>
                    <b-skeleton-table
                    :rows="4"
                    :columns="fields.length"
                    :table-props="{
                        hover: true,
                        noBorderCollapse: true,
                    }"
                    />
                </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                align="right"
                variant="danger"
            />
        </template>
    </form-modal>
</template>

<script>
import CommercialService from '@src/services/CommercialService'

import FormModal from '@/src/components/FormModal.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import extraPointOrigins from '@src/mixins/intelligence/commercial/extraPointOrigins'
import extraPointTypes from '@src/mixins/intelligence/commercial/extraPointTypes'
import promotionTypes from '@src/mixins/intelligence/commercial/promotionTypes'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
    },
    mixins: [
        vuetableFormatters,
        extraPointOrigins,
        extraPointTypes,
        promotionTypes,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: true,
            items: [],
            currentPage: 1,
            perPage: 10,
            totalItems: 0,
            id: '',
            fields: [
                {
                    key: 'product.name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'product.reference',
                    label: this.getI18n('PRODUCTS', 'FIELDS.reference'),
                    sortable: true,
                },
                {
                    key: 'product.category.name',
                    label: this.getI18n(i18nCommon, 'category'),
                    sortable: true,
                },
                {
                    key: 'start',
                    label: this.getI18n(i18nCommon, 'start_date'),
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'end',
                    label: this.getI18n(i18nCommon, 'end_date'),
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'boxes_quantity',
                    label:this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.box',
                        modifier: 2,
                    }),
                    sortable: true,
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    key: 'shares_quantity',
                    label: this.getI18n(i18nKey, 'TITLES.share_dashboard'),
                    sortable: true,
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    key: 'minimum_price',
                    label: this.getI18n('PRODUCTS', 'FIELDS.min_price'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'maximum_price',
                    label: this.getI18n('PRODUCTS', 'FIELDS.max_price'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'extra_point_type',
                    label: `${this.getI18n(i18nKey, 'TITLES.additional_point')} ${this.getI18n(i18nCommon, 'type')}`,
                    sortable: true,
                    formatter: this.extraPointTypesToText,
                },
                {
                    key: 'extra_point_origin',
                    label: `${this.getI18n(i18nKey, 'TITLES.additional_point')} ${this.getI18n(i18nCommon, 'origin')}`,
                    sortable: true,
                    formatter: this.extraPointOriginsToText,
                },
                {
                    key: 'extra_point_quantity',
                    label: `${this.getI18n(i18nKey, 'TITLES.additional_point')} ${this.getI18n(i18nCommon, 'quantity')}`,
                    sortable: true,
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    key: 'tabloid_start',
                    label: `${this.getI18n('QUESTIONS', 'SUBTYPES.tabloid')} ${this.getI18n(i18nCommon, 'start_date')}`,
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'tabloid_end',
                    label: `${this.getI18n('QUESTIONS', 'SUBTYPES.tabloid')} ${this.getI18n(i18nCommon, 'end_date')}`,
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'sale_type',
                    label: `${this.getI18n(i18nCommon, 'promotion')} ${this.getI18n(i18nCommon, 'type')}`,
                    sortable: true,
                    formatter: this.promotionTypesToText,
                },
                {
                    key: 'sale_start',
                    label: `${this.getI18n(i18nCommon, 'promotion')} ${this.getI18n(i18nCommon, 'start_date')}`,
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'sale_end',
                    label: `${this.getI18n(i18nCommon, 'promotion')} ${this.getI18n(i18nCommon, 'end_date')}`,
                    sortable: true,
                    formatter: this.formatDateShort,
                },
                {
                    key: 'sale_minimum_price',
                    label: `${this.getI18n(i18nCommon, 'promotion')} ${this.getI18n('PRODUCTS', 'FIELDS.min_price')}`,
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'sale_maximum_price',
                    label: `${this.getI18n(i18nCommon, 'promotion')} ${this.getI18n('PRODUCTS', 'FIELDS.max_price')}`,
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
            ]
        }
    },
    computed: {
        titleFormatted() {
            return `${this.getI18n(i18nCommon, 'request')} Nº ${this.id}`
        },
    },
    methods: {
        showModal(id) {
            this.id = id

            this.$nextTick(() => {
                this.$refs.formModal.show()
                this.refresh()
            })
        },
        refresh() {
            this.loading = true

            CommercialService.fetchAll({
                with: ['products']
            }, `negotiations-items/${this.id}`)
                .then((response) => {
                    this.items = response.data.data

                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
