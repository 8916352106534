<script>
const i18nKey = 'EXTRA_POINTS'

export default {
    data() {
        return {
            extraPointTypes: [
                {
                    text: this.extraPointTypesToText('checkout'),
                    value: 'checkout',
                },
                {
                    text: this.extraPointTypesToText('display'),
                    value: 'display',
                },
                {
                    text: this.extraPointTypesToText('island'),
                    value: 'island',
                },
                {
                    text: this.extraPointTypesToText('pallet'),
                    value: 'pallet',
                },
                {
                    text: this.extraPointTypesToText('gondola_point'),
                    value: 'gondola_point',
                },
                {
                    text: this.extraPointTypesToText('terminal'),
                    value: 'terminal',
                },
                {
                    text: this.extraPointTypesToText('other'),
                    value: 'other',
                },
            ],
        }
    },

    methods: {
        extraPointTypesToText(value) {
            return value ? this.getI18n(i18nKey, `TYPES.${value}`) : null
        },
    },
}
</script>
