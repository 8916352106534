<script>
const i18nKey = 'PROMOTIONS'

export default {
    data() {
        return {
            promotionTypes: [
                {
                    text: this.promotionTypesToText('pullout'),
                    value: 'pullout',
                },
                {
                    text: this.promotionTypesToText('share_gondola'),
                    value: 'share_gondola',
                },
                {
                    text: this.promotionTypesToText('product_introduction'),
                    value: 'product_introduction',
                },
                {
                    text: this.promotionTypesToText('price_offer'),
                    value: 'price_offer',
                },
                {
                    text: this.promotionTypesToText('extra_point'),
                    value: 'extra_point',
                },
            ],
        }
    },

    methods: {
        promotionTypesToText(value) {
            return value ? this.getI18n(i18nKey, `TYPES.${value}`) : null
        },
    },
}
</script>
