<script>
const i18nKey = 'EXTRA_POINTS'

export default {
    data() {
        return {
            extraPointOrigins: [
                {
                    text: this.extraPointOriginsToText('purchased'),
                    value: 'purchased',
                },
                {
                    text: this.extraPointOriginsToText('conquered'),
                    value: 'conquered',
                },
            ],
        }
    },

    methods: {
        extraPointOriginsToText(value) {
            return value ? this.getI18n(i18nKey, `ORIGINS.${value}`) : null
        },
    },
}
</script>
