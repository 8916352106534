<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nChain"
                                label-for="filter-chain"
                                class="label-pdv"
                            >
                                <multiSelectWithService
                                    :id="'filter-chain'"
                                    ref="chainMultiselect"
                                    v-model="filters.chains"
                                    :service="'pos-chains'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="parameters"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nOrderHistory" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder"
                    >
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openProductsModal(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <order-history-filter-modal ref="orderHistoryFilterModal" :handle-submit="filter" />
        <order-history-products-modal ref="orderHistoryProductsModal" />
    </Layout>
</template>

<script>
import CommercialService from '@src/services/CommercialService'

import Layout from '@layouts/main'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import orderHistoryFilterModal from '@src/router/views/commercial/orderHistory/orderHistoryFilterModal.vue'
import orderHistoryProductsModal from '@src/router/views/commercial/orderHistory/orderHistoryProductsModal.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import storeProfileTypes from '@src/mixins/intelligence/costOfServing/storeProfileTypes'
import costOfServingFormatters from '@src/mixins/intelligence/costOfServing/costOfServingFormatters.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import validationMixin from '@src/mixins/validation.vue'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        MoreFiltersLabelButton,
        FilterButton,
        DateRangePicker,
        orderHistoryFilterModal,
        orderHistoryProductsModal,
        multiSelectWithService,
        MoreTableButton,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter,
        storeProfileTypes,
        costOfServingFormatters,
        validationMixin,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            perPage: 10,
            additionalParameters: {
                start: moment().startOf('day').add(-7, 'days').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            sortOrder: [
                {
                    field: 'id',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'id',
                    title: this.getI18n(i18nCommon, 'number'),
                },
                {
                    name: 'business_unit.name',
                    title: this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit'),
                },
                {
                    name: 'point_of_sale.name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                },
                {
                    name: 'point_of_sale_chain.name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                },
                {
                    name: 'products_quantity',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'boxes_quantity',
                    title: this.getI18n(i18nCommon, 'volume'),
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filters: {
                date: {
                    start: moment().startOf('day').add(-7, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                regionals: [],
                chains: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18n(i18nCommon, 'commercial'),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.order_history'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nInside() {
            return this.getI18n(i18nCommon, 'inside')
        },
        i18nAlert() {
            return this.getI18n(i18nCommon, 'alert')
        },
        i18nOutside() {
            return this.getI18n(i18nCommon, 'outside')
        },
        i18nRulesNotConfigured() {
            return this.getI18n('BUSINESS_UNITS', 'MESSAGES.rules_not_configured')
        },
        i18nOrderHistory() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.order_history',
                modifier: 1,
            })
        },
    },
    methods: {
        createResumeParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            this.$v.filters.$touch()
            if (appliedFilters || (!this.$v.filters.$invalid && !this.loading)) {
                if (appliedFilters) {
                    this.filters = appliedFilters
                }
                this.additionalParameters = this.createResumeParameters()
                this.refreshTable()
            }
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.orderHistoryFilterModal.showModal(this.filters))
        },
        openProductsModal(data) {
            this.$nextTick(() => this.$refs.orderHistoryProductsModal.showModal(data.id))
        },
        fetch(url, params) {
            return CommercialService.fetchVuetable('negotiations', params)
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    }
}
</script>
